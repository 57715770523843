<template>
  <div class="flex justify-center py-10 font-thin text-justify">
    <div class="fmu-container">
      <strong>Impressum</strong><br><br>

      <strong>Angaben gemäß § 5 TMG:</strong> <br><br>

      Hutter & Unger GmbH<br>
      Gewerbestraße 2c<br>
      Gewerbegebiet Geratshofen<br>
      86637 Wertingen<br><br>

      <strong>Vertreten durch:</strong><br>
      <br>
      Geschäftsführende Gesellschafter:<br>
      Christoph Hutter und Andreas Unger<br><br>

      <strong>Kontakt:</strong><br><br>

      Telefon: <a href="tel:+49827299400" class="underline text-hu-primary">+49 (0)8272 9940-0</a> <br>
      Telefax: +49 (0)8272 9940-10 <br>
      E-Mail: <a href="mailto:info@hutter-unger.de" class="underline text-hu-primary">info@hutter-unger.de</a> <br><br>

      <strong>Registereintrag:</strong><br><br>

      Eintragung im Handelsregister. <br>
      Registergericht: Augsburg <br>
      Registernummer: HRB 18292 <br><br>

      <strong>Umsatzsteuer:</strong> <br><br>

      Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br>
      DE213525041<br><br>

      <strong>Verantwortlich im Sinne des Presserechtes:</strong><br><br>

      Christoph Hutter <br>
      Hutter & Unger GmbH <br>
      Gewerbestr. 2c <br>
      86637 Wertingen <br>

      E-Mail: <a href="mailto:info@hutter-unger.de" class="underline text-hu-primary">info@hutter-unger.de</a><br>
      Telefon: <a href="tel:+49827299400" class="underline text-hu-primary">+49 (0)8272 9940-0</a> <br>

      <strong>Streitschlichtung</strong><br><br>

      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
      https://ec.europa.eu/consumers/odr.
      Unsere E-Mail-Adresse findest Du oben im Impressum.

      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
      teilzunehmen.<br><br>

      <strong>Haftung für Inhalte</strong><br><br>

      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
      Gesetzen
      verantwortlich.
      Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
      Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen.<br><br>

      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
      hiervon unberührt.
      Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
      möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
      entfernen.<br><br>

      <strong>Haftung für Links</strong><br><br>

      Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
      können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
      Betreiber der Seiten verantwortlich.
      Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
      Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br><br>

      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
      Rechtsverletzung nicht zumutbar.
      Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br><br>

      <strong>Urheberrecht</strong><br><br>

      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
      Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
      Urheberrechtes bedürfen der schriftlichen Zustimmung des
      jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
      kommerziellen Gebrauch gestattet.<br><br>

      Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
      beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Solltest Du trotzdem auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
    </div>
  </div>
</template>

<script>
export default {
  name: 'Impress',
  components: {},
}
</script>

<style scoped>
</style>
